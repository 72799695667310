export enum UNIVERSAL_ROUTE {
  ROOT = '/',
  OTHER = '*',
  NOT_FOUND = '404',
  LOGIN = 'login',
  REGISTER = 'register',
  ID = ':id',
}

export enum FIRST_ROUTE {
  SCHEDULE = 'schedule',
  ACCOUNT = 'account',
  COURSE = 'course',
  CROSS_SUBJECT = 'cross-subject',
  NOTIFICATION = 'notification',
  TEACHER_APPLICATION = 'application',
  ONE_ANSWER = 'one-answer',
  TEACHER_TERMINATED_CONTRACT = 'teacherTerminatedContract',
  REQUEST_SIGN_EMPLOYMENT_CONTRACT = 'requestSignEmploymentContract',
}
export enum FIRST_ROUTE_NAME {
  SCHEDULE = 'Schedule',
  ACCOUNT = 'Account',
  COURSE = 'Course',
  CROSS_SUBJECT = 'Cross subject',
  TEACHER_APPLICATION = 'Application',
  NOTIFICATION = 'Notification',
  ONE_ANSWER = 'OneAnswer',
  TEACHER_TERMINATED_CONTRACT = 'Teacher terminated contract',
  REQUEST_SIGN_EMPLOYMENT_CONTRACT = 'Teacher employment contract',
}

export enum SECOND_ROUTE {
  ASK = 'ask',
  LIST = 'list',
  FEEDBACK = 'feedback',
  TRIAL_TEACHING = 'trial-teaching',
  TRIAL_INFORMATION = 'trialInformation',
  APPLICATION_STATUS = 'status',
  TEACHER_EXPERIENCE = 'personal-experience',
  TEACHER_EMPLOYMENT_CONTRACT = 'teacherEmploymentContract',
  PROFILE = 'profile',
  LEAVE_RECORD = 'leave-record',
  COMPENSATION_CONFIRMATION = 'compensation-confirmation',
}
export enum SECOND_ROUTE_NAME {
  ASK = 'Ask',
  list = 'List',
  FEEDBACK = 'Feedback',
  APPLICATION = 'Application record',
  TRIAL_INSTRUCTIONS = 'Trial instructions',
  APPLICATION_STATUS = 'Application status',
  PROFILE = 'Profile',
  LEAVE_RECORD = 'Leave record',
  COMPENSATION_CONFIRMATION = 'Compensation Confirmation',
}
export enum THIRD_ROUTE {
  CREATE = 'create',
}
export enum THIRD_ROUTE_NAME {
  CREATE = 'Create',
}

export const concatPath = (paths: string[]) =>
  `${UNIVERSAL_ROUTE.ROOT}${paths.join('/')}`;
export const PATH = {
  ROOT: concatPath([]),
  OTHER: concatPath([UNIVERSAL_ROUTE.OTHER]),
  NOT_FOUND: concatPath([UNIVERSAL_ROUTE.NOT_FOUND]),
  LOGIN: concatPath([UNIVERSAL_ROUTE.LOGIN]),
  REGISTER: concatPath([UNIVERSAL_ROUTE.REGISTER]),
  SCHEDULE: concatPath([FIRST_ROUTE.SCHEDULE]),
  TEACHER_APPLICATION: concatPath([FIRST_ROUTE.TEACHER_APPLICATION]),
  TEACHER_APPLICATION_CREATE: concatPath([
    FIRST_ROUTE.TEACHER_APPLICATION,
    SECOND_ROUTE.TRIAL_TEACHING,
  ]),
  TEACHER_EXPERIENCE: concatPath([
    FIRST_ROUTE.TEACHER_APPLICATION,
    SECOND_ROUTE.TEACHER_EXPERIENCE,
  ]),
  TEACHER_EMPLOYMENT_CONTRACT: concatPath([
    FIRST_ROUTE.TEACHER_APPLICATION,
    SECOND_ROUTE.TEACHER_EMPLOYMENT_CONTRACT,
  ]),
  CROSS_SUBJECT: concatPath([FIRST_ROUTE.CROSS_SUBJECT]),
  CROSS_SUBJECT_TRIAL_INFORMATION: concatPath([
    FIRST_ROUTE.CROSS_SUBJECT,
    SECOND_ROUTE.TRIAL_INFORMATION,
  ]),
  CROSS_SUBJECT_APPLICATION_STATUS: concatPath([
    FIRST_ROUTE.CROSS_SUBJECT,
    SECOND_ROUTE.APPLICATION_STATUS,
  ]),
  TEACHER_TERMINATED_CONTRACT: concatPath([
    FIRST_ROUTE.TEACHER_TERMINATED_CONTRACT,
  ]),
  REQUEST_SIGN_EMPLOYMENT_CONTRACT: concatPath([
    FIRST_ROUTE.REQUEST_SIGN_EMPLOYMENT_CONTRACT,
  ]),
  ACCOUNT: concatPath([FIRST_ROUTE.ACCOUNT]),
  ACCOUNT_PROFILE: concatPath([FIRST_ROUTE.ACCOUNT, SECOND_ROUTE.PROFILE]),
  ACCOUNT_LEAVE_RECORD: concatPath([
    FIRST_ROUTE.ACCOUNT,
    SECOND_ROUTE.LEAVE_RECORD,
  ]),
  ACCOUNT_COMPENSATION_CONFIRMATION: concatPath([
    FIRST_ROUTE.ACCOUNT,
    SECOND_ROUTE.COMPENSATION_CONFIRMATION,
  ]),
  ACCOUNT_COMPENSATION_CONFIRMATION_ID: concatPath([
    FIRST_ROUTE.ACCOUNT,
    SECOND_ROUTE.COMPENSATION_CONFIRMATION,
    UNIVERSAL_ROUTE.ID,
  ]),
  COURSE: concatPath([FIRST_ROUTE.COURSE]),
  COURSE_ASK: concatPath([FIRST_ROUTE.COURSE, SECOND_ROUTE.ASK]),
  COURSE_LIST: concatPath([FIRST_ROUTE.COURSE, SECOND_ROUTE.LIST]),
  COURSE_FEEDBACK: concatPath([FIRST_ROUTE.COURSE, SECOND_ROUTE.FEEDBACK]),
  COURSE_FEEDBACK_CREATE: concatPath([
    FIRST_ROUTE.COURSE,
    SECOND_ROUTE.FEEDBACK,
    THIRD_ROUTE.CREATE,
  ]),
  COURSE_FEEDBACK_CREATE_ID: concatPath([
    FIRST_ROUTE.COURSE,
    SECOND_ROUTE.FEEDBACK,
    THIRD_ROUTE.CREATE,
    UNIVERSAL_ROUTE.ID,
  ]),
  COURSE_FEEDBACK_ID: concatPath([
    FIRST_ROUTE.COURSE,
    SECOND_ROUTE.FEEDBACK,
    UNIVERSAL_ROUTE.ID,
  ]),
  NOTIFICATION: concatPath([FIRST_ROUTE.NOTIFICATION]),
  ONE_ANSWER: concatPath([FIRST_ROUTE.ONE_ANSWER]),
};
