export enum ERemoteConfigParameter {
  TEACHER_LEAVE = 'TEACHER_LEAVE',
  TEACHER_REGISTER = 'TEACHER_REGISTER',
  TEACHER_PROGRESS = 'TEACHER_PROGRESS',
  TEACHER_APPLICATION = 'TEACHER_APPLICATION',
  TEACHER_SIGNING = 'TEACHER_SIGNING',
  NTCHR_410 = 'NTCHR_410',
  ONE_ANSWER = 'ONE_ANSWER',
  ONE_ANSWER_WHITEBOARD = 'ONE_ANSWER_WHITEBOARD',
  ONE_ANSWER_ENDING_TASK = 'ONE_ANSWER_ENDING_TASK',
  ONE_ANSWER_ABANDON_TASK = 'ONE_ANSWER_ABANDON_TASK',
  DUPLICATE_SHIFT = 'DUPLICATE_SHIFT', // 重複排課
  EDIT_BANK_ACCOUNT_FEATURE = 'EDIT_BANK_ACCOUNT_FEATURE',

  /** 老師搶課 */
  TEACHER_COURSE_CLAIM = 'TEACHER_COURSE_CLAIM',
}
