import { EApiStatus } from 'constants/common';
import { useLoadingService } from 'services/LoadingService';
import { t } from 'utils/i18n';
import axios from 'plugins/api/axios';
import { useSnackbar } from './useSnackbar';

const { oneClubGateway } = axios;

type ExtendsProps = {
  organizationId?: string;
  groupId?: string;
  organizationName?: string;
  groupName?: string;
};

export const useTutorialInfo = () => {
  const { showLoading, hideLoading } = useLoadingService();
  const { enqueueSnackbar } = useSnackbar();

  const getTutorialInfo = async <T extends ExtendsProps>({
    arr,
    teacherOneClubId,
  }: {
    arr?: T[];
    teacherOneClubId?: string | null;
  }) => {
    if (!arr) return [];
    const newArr: T[] = JSON.parse(JSON.stringify(arr));
    if (!teacherOneClubId) return newArr;

    const organizationIds = [
      ...new Set(newArr.map(ask => ask.organizationId).filter(Boolean)),
    ] as string[];
    const groupIds = [
      ...new Set(newArr.map(ask => ask.groupId).filter(Boolean)),
    ] as string[];

    if (!organizationIds.length && !groupIds.length) return newArr;

    showLoading();
    // 取得機構名稱
    const organizationNamesResponse = oneClubGateway.getOrganizationNames({
      organizationIds,
    });

    // 取得班級名稱
    const groupNamesResponse = oneClubGateway.getGroupNames({
      groupIds,
    });

    const [organization, group] = await Promise.all([
      organizationNamesResponse,
      groupNamesResponse,
    ]);

    if (organization.status === EApiStatus.SUCCESS) {
      organization.data.forEach(org => {
        newArr.forEach(item => {
          if (item.organizationId === org.id) {
            item.organizationName = org.officialName;
          }
        });
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getOrganizationDataFailed', '取得機構資料失敗'),
        { variant: 'error' },
      );
    }

    if (group.status === EApiStatus.SUCCESS) {
      group.data.forEach(grp => {
        newArr.forEach(item => {
          if (item.groupId === grp.id) {
            item.groupName = grp.name;
          }
        });
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getGroupDataFailed', '取得班級資料失敗'),
        { variant: 'error' },
      );
    }
    hideLoading();

    return newArr;
  };

  const getTutorialInfoWithoutLoading = async <T extends ExtendsProps>({
    arr,
    teacherOneClubId,
  }: {
    arr?: T[];
    teacherOneClubId?: string | null;
  }) => {
    if (!arr) return [];
    const newArr: T[] = JSON.parse(JSON.stringify(arr));
    if (!teacherOneClubId) return newArr;

    const organizationIds = [
      ...new Set(newArr.map(ask => ask.organizationId).filter(Boolean)),
    ] as string[];
    const groupIds = [
      ...new Set(newArr.map(ask => ask.groupId).filter(Boolean)),
    ] as string[];

    if (!organizationIds.length && !groupIds.length) return newArr;

    // 取得機構名稱
    const organizationNamesResponse = oneClubGateway.getOrganizationNames({
      organizationIds,
    });

    // 取得班級名稱
    const groupNamesResponse = oneClubGateway.getGroupNames({
      groupIds,
    });

    const [organization, group] = await Promise.all([
      organizationNamesResponse,
      groupNamesResponse,
    ]);

    if (organization.status === EApiStatus.SUCCESS) {
      organization.data.forEach(org => {
        newArr.forEach(item => {
          if (item.organizationId === org.id) {
            item.organizationName = org.officialName;
          }
        });
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getOrganizationDataFailed', '取得機構資料失敗'),
        { variant: 'error' },
      );
    }

    if (group.status === EApiStatus.SUCCESS) {
      group.data.forEach(grp => {
        newArr.forEach(item => {
          if (item.groupId === grp.id) {
            item.groupName = grp.name;
          }
        });
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getGroupDataFailed', '取得班級資料失敗'),
        { variant: 'error' },
      );
    }

    return newArr;
  };

  const getTutorialInfoForSingle = async <T extends ExtendsProps>({
    data,
    teacherOneClubId,
  }: {
    data?: T;
    teacherOneClubId?: string | null;
  }) => {
    const newData: T = JSON.parse(JSON.stringify(data));
    if (!teacherOneClubId) return newData;

    const organizationIds = [newData.organizationId] as string[];
    const groupIds = [newData.groupId] as string[];

    if (!organizationIds.length && !groupIds.length) return newData;

    // 取得機構名稱
    const organizationNamesResponse = oneClubGateway.getOrganizationNames({
      organizationIds,
    });

    // 取得班級名稱
    const groupNamesResponse = oneClubGateway.getGroupNames({
      groupIds,
    });

    const [organization, group] = await Promise.all([
      organizationNamesResponse,
      groupNamesResponse,
    ]);

    if (organization.status === EApiStatus.SUCCESS) {
      organization.data.forEach(org => {
        newData.organizationName = org.officialName;
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getOrganizationDataFailed', '取得機構資料失敗'),
        { variant: 'error' },
      );
    }

    if (group.status === EApiStatus.SUCCESS) {
      group.data.forEach(grp => {
        newData.groupName = grp.name;
      });
    } else {
      enqueueSnackbar(
        t('hooks.tutorialInfo.getGroupDataFailed', '取得班級資料失敗'),
        { variant: 'error' },
      );
    }
    hideLoading();

    return newData;
  };

  return {
    getTutorialInfo,
    getTutorialInfoWithoutLoading,
    getTutorialInfoForSingle,
  };
};
