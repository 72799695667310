import { TTimeRangeParams } from 'types/common/common.type';
import { AxiosInstance } from 'axios';
import {
  IGetMessageRequestsParams,
  IGetRequestMessageResponse,
} from 'types/course';
import { TApiResponse } from 'types/common';
import { IProfile, TProfileResponse } from 'types/profile';
import {
  DateRange,
  PostDurationSchedulesPayload,
  PostDurationSchedulesResponse,
  ScheduleResponse,
  SchedulesBody,
  SchedulesParams,
} from 'types/schedule';
import {
  IGetCourseReminderResponse,
  IGetNormalMessageResponse,
} from 'types/notification';
import {
  InitTeacherResponse,
  InitTeacherBodyByTeacherRecruitment,
  InitTeacherBody,
} from 'types/teacher';

export const teachersApi = (axios: AxiosInstance, teacher: string) => ({
  initTeacherByTeacherRecruitment(
    data: InitTeacherBodyByTeacherRecruitment,
  ): TApiResponse<InitTeacherResponse> {
    return axios.post(
      `${teacher}/open/teachers/create-teacher-via-oneclub-system`,
      data,
    );
  },
  initTeacher(data: InitTeacherBody): TApiResponse<InitTeacherResponse> {
    return axios.post(`${teacher}/mms/teachers`, data);
  },
  /**
   * Get the profile of teachers
   */
  getMe(): TApiResponse<TProfileResponse> {
    return axios.get(`${teacher}/teachers/me`);
  },
  /**
   * Update teacher profile
   */
  updateMe(payload: IProfile): TApiResponse<TProfileResponse> {
    return axios.patch(`${teacher}/teachers/info`, payload);
  },
  /**
   * Added new class
   */
  postSchedule(data: SchedulesBody): TApiResponse<ScheduleResponse> {
    return axios.post(`${teacher}/schedules`, data);
  },
  /**
   * New duplication cycle
   */
  postDurationSchedule(
    data: PostDurationSchedulesPayload,
  ): TApiResponse<PostDurationSchedulesResponse> {
    return axios.post(`${teacher}/schedules/durations`, data);
  },
  /**
   * Obtain schedule information
   */
  getSchedules(params: SchedulesParams): TApiResponse<ScheduleResponse[]> {
    return axios.get(`${teacher}/schedules`, { params });
  },
  /**
   * Edit class class
   */
  updateSchedule(
    id: string,
    data: SchedulesBody,
  ): TApiResponse<ScheduleResponse> {
    return axios.put(`${teacher}/schedules/${id}`, data);
  },
  /**
   * Delete the ranking
   */
  deleteSchedule(id: string): TApiResponse<ScheduleResponse> {
    return axios.delete(`${teacher}/schedules/${id}`);
  },
  /**
   * Clear line (time period)
   */
  clearSchedule(payload: DateRange): TApiResponse<ScheduleResponse> {
    return axios.post(`${teacher}/schedules/duration-delete`, payload);
  },
  /**
   * List of classes requesting
   */
  getRequestMessages(
    params: IGetMessageRequestsParams,
  ): TApiResponse<IGetRequestMessageResponse> {
    return axios.get(`${teacher}/messages/request`, { params });
  },
  /**
   * General notification list
   */
  getNormalMessages(
    params: IGetMessageRequestsParams,
  ): TApiResponse<IGetNormalMessageResponse> {
    return axios.get(`${teacher}/messages/normal`, { params });
  },
  /**
   * Pre -class status list
   */
  readCourseReminderMessage(
    params: TTimeRangeParams,
  ): TApiResponse<IGetCourseReminderResponse> {
    return axios.get(`${teacher}/messages/course-reminder`, { params });
  },
  /**
   * Change the unreasonable state
   */
  readMessage(ids: string[]): TApiResponse<any> {
    return axios.patch(`${teacher}/messages/read-messages`, { ids });
  },

  /**
   * Get server time
   */
  getServerTime(): TApiResponse<string> {
    return axios.get(`${teacher}/common-tool/getServerTime`);
  },

  /**
   * Delete teacher (by login token)
   */
  deleteTeacher(): TApiResponse<{ oneClubId: string }> {
    return axios.delete(`${teacher}/teachers`);
  },
});
