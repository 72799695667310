import { EApiStatus } from 'constants/common';
import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { MenuPopover, IconButtonAnimate, Mui } from '@onedesign/ui';
import { useLoginService } from 'services/LoginService';
import { useProfileService } from 'services/ProfileService';
import { IProfile } from 'types/profile';
import { t } from 'utils/i18n';
import { useLoadingService } from 'services/LoadingService';
import axios from 'plugins/api/axios';
import { Icon, SvgName } from 'components/Icon';
import { Dialog } from 'components/Dialog';

const { Box, Divider, Typography, MenuItem, Avatar, IconButton } = Mui;
// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     linkTo: '/',
//   },
//   {
//     label: 'Settings',
//     linkTo: '/',
//   },
// ];

// ----------------------------------------------------------------------

const { teachers } = axios;

export default () => {
  const { logout } = useLoginService();
  const { profile } = useProfileService();
  const { avatarImage, nickName, oneClubId } = profile as IProfile;
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { showLoading, hideLoading } = useLoadingService();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] =
    useState<boolean>(false);
  const onCloseConfirmDialog = () => setIsOpenConfirmDialog(false);
  const onOpenConfirmDialog = () => setIsOpenConfirmDialog(true);

  const onConfirmDeleAccount = async () => {
    showLoading();
    const { status, data } = await teachers.deleteTeacher();

    if (status === EApiStatus.SUCCESS && data?.oneClubId) {
      logout();
    }

    onCloseConfirmDialog();
    hideLoading();
  };
  const openRecommendationSystem = () => {
    const urlReference = import.meta.env.VITE_REFERENCE_SYSTEM_PAGE;

    window.open(`${urlReference}`, '_blank');
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
        css={{}}
      >
        <Avatar src={avatarImage || ''} alt="oneClub" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              width: '80%',
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {nickName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {oneClubId}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={onOpenConfirmDialog}>
              <Icon
                sx={{ width: '36px', height: '36px' }}
                name={SvgName.TrashThin}
              />
            </IconButton>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {
            MENU_OPTIONS.map(option => (
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))
          }
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem sx={{ m: 1 }} onClick={openRecommendationSystem}>
          {t(
            'layouts.dashboard.header.accountPopover.openRecommendationSystem',
            '教師推薦系統',
          )}
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={logout}>
          {t('layouts.dashboard.header.accountPopover.signOut', '登出')}
        </MenuItem>
      </MenuPopover>

      <Dialog
        maxWidth="sm"
        width="100%"
        fullWidth={true}
        title={t('profile.banner.deleteAccountText', '刪除帳號')}
        open={isOpenConfirmDialog}
        close={{
          show: true,
          text: 'X',
          action: onCloseConfirmDialog,
        }}
        disConfirm={{
          text: t('profile.banner.cancel', '取消'),
          action: onCloseConfirmDialog,
          show: true,
        }}
        confirm={{
          text: t('profile.banner.confirm', '確認刪除'),
          color: 'error',
          action: onConfirmDeleAccount,
        }}
        children={
          <Typography>
            {t(
              'profile.banner.warningMessageDeleteAccount',
              '若點擊刪除帳號，您的資料將被刪除且永久無法登入',
            )}
          </Typography>
        }
      />
    </>
  );
};
