import { lazyLoad } from 'routes/LazyLoad';
import { Navigate, RouteObject } from 'react-router-dom';
import { PATH } from '../routeConfig';

export const profileRoutes: RouteObject[] = [
  {
    element: <Navigate to={PATH.ACCOUNT_PROFILE} />,
    index: true,
  },
  {
    path: PATH.ACCOUNT_PROFILE,
    element: lazyLoad(() => import('pages/Profile')),
  },
  {
    path: PATH.ACCOUNT_LEAVE_RECORD,
    element: lazyLoad(() => import('pages/LeaveRecord')),
  },
  {
    path: PATH.ACCOUNT_COMPENSATION_CONFIRMATION,
    element: lazyLoad(() => import('pages/CompensationConfirmation')),
    children: [
      {
        element: lazyLoad(
          () => import('pages/CompensationConfirmation/overview-table'),
        ),
        index: true,
      },
      {
        path: ':id',
        element: lazyLoad(
          () => import('pages/CompensationConfirmation/detail'),
        ),
      },
    ],
  },
];
