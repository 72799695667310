import { EApiStatus } from 'constants/common';
import { fToDate, fToTime } from 'utils/formatTime';
import { assign, createMachine } from 'xstate';
import {
  DIALOG_TYPE,
  DIALOG_ACTIONS,
  DIALOG_SERVICES,
} from './scheduleDialogMachine.constant';
import {
  DialogActionEvent,
  DialogContext,
  DialogEvent,
  DialogState,
} from './scheduleDialogMachine.type';

const dialogState: DialogContext = {
  title: [
    'machines.scheduleDialogMachine.dialogActions.createScheduleContext.title',
    '新增排班',
  ],
  showInputs: true,
  description: ['', ''],
  showCancel: true,
  showClose: true,
  cancelText: [
    'machines.scheduleDialogMachine.dialogActions.createScheduleContext.cancel',
    '取消',
  ],
  confirmText: [
    'machines.scheduleDialogMachine.dialogActions.createScheduleContext.confirm',
    '確認排班',
  ],
  confirmColor: 'primary',
};
export const scheduleDialogMachine = createMachine<
  DialogContext,
  DialogEvent,
  DialogState
>(
  {
    id: 'scheduleDialogMachine',
    predictableActionArguments: true,
    initial: DIALOG_TYPE.IDLE,
    context: dialogState,
    states: {
      idle: {
        invoke: {
          src: 'initialContext',
        },
        on: {
          SWITCH: [
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_CREATE,
              actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_CREATE,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_EDIT,
              actions: [DIALOG_ACTIONS.EDIT_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_EDIT,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_DELETE,
              actions: [DIALOG_ACTIONS.DELETE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_DELETE,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_CLEAR,
              actions: [DIALOG_ACTIONS.CLEAR_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_CLEAR,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_NOT_CHANGED,
              actions: [DIALOG_ACTIONS.NOT_CHANGE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_NOT_CHANGED,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_NOT_ADDED,
              actions: [DIALOG_ACTIONS.NOT_ADDED_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_NOT_ADDED,
            },
          ],
        },
      },
      [DIALOG_TYPE.SCHEDULE_CONFIRMING]: {
        invoke: {
          src: DIALOG_SERVICES.CHECK_CALENDAR_EVENT,
          onDone: [
            {
              cond: (context, event) =>
                !event.data.conflictStatus &&
                event.data.dialogType === DIALOG_TYPE.SCHEDULE_CLEAR,
              actions: [DIALOG_ACTIONS.CLEAR_SCHEDULE_CONFIRMING_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_CLEAR_CONFIRMING,
            },
            {
              cond: (context, event) => event.data.conflictStatus,
              actions: [DIALOG_ACTIONS.CONFLICT_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_CONFLICT,
            },
            {
              cond: (context, event) =>
                !event.data.conflictStatus &&
                event.data?.apiStatus === EApiStatus.SUCCESS,
              actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_SUCCESS,
            },
            {
              cond: (context, event) =>
                event.data?.apiStatus === EApiStatus.FAILURE,
              actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_FAILURE,
            },
          ],
        },
      },
      [DIALOG_TYPE.SCHEDULE_CREATE]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: {
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_EDIT]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: {
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_DELETE]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: {
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_CLEAR]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: {
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_CLEAR_CONFIRMING]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: {
            target: DIALOG_TYPE.SCHEDULE_CLEAR,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_CONFLICT]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_CONFIRMING,
          },
          CANCEL: [
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_CREATE,
              actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_CREATE,
            },
            {
              cond: (context, event) =>
                event.dialogType === DIALOG_TYPE.SCHEDULE_EDIT,
              actions: [DIALOG_ACTIONS.EDIT_SCHEDULE_CONTEXT],
              target: DIALOG_TYPE.SCHEDULE_EDIT,
            },
          ],
        },
      },
      [DIALOG_TYPE.SCHEDULE_NOT_CHANGED]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_SUCCESS,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_NOT_ADDED]: {
        on: {
          CONFIRM: {
            target: DIALOG_TYPE.SCHEDULE_SUCCESS,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_SUCCESS]: {
        on: {
          SWITCH: {
            actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
      [DIALOG_TYPE.SCHEDULE_FAILURE]: {
        on: {
          SWITCH: {
            actions: [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT],
            target: DIALOG_TYPE.IDLE,
          },
        },
      },
    },
  },
  {
    actions: {
      [DIALOG_ACTIONS.CREATE_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.createScheduleContext.title',
          '新增排班',
        ],
        showInputs: true,
        description: ['', ''],
        showCancel: true,
        showClose: true,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.createScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.createScheduleContext.confirm',
          '確認排班',
        ],
        confirmColor: 'primary',
        errorMsg: (_, event) => {
          const { data } = event as unknown as { data: DialogActionEvent };
          return data?.errorMsg || '';
        },
      }),
      [DIALOG_ACTIONS.EDIT_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.editScheduleContext.title',
          '編輯排班',
        ],
        showInputs: true,
        description: ['', ''],
        showCancel: true,
        showClose: true,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.editScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.editScheduleContext.confirm',
          '儲存',
        ],
        confirmColor: 'primary',
      }),
      [DIALOG_ACTIONS.DELETE_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.deleteScheduleContext.title',
          '刪除排班',
        ],
        showInputs: false,
        description: [
          'machines.scheduleDialogMachine.dialogActions.deleteScheduleContext.description',
          '確認清除此時段?',
        ],
        showCancel: true,
        showClose: true,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.deleteScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.deleteScheduleContext.confirm',
          '清除',
        ],
        confirmColor: 'error',
      }),
      [DIALOG_ACTIONS.CLEAR_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleContext.confirm',
          '清除排班',
        ],
        showInputs: true,
        description: ['', ''],
        showCancel: true,
        showClose: true,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleContext.confirm',
          '清除',
        ],
        confirmColor: 'error',
      }),
      [DIALOG_ACTIONS.CLEAR_SCHEDULE_CONFIRMING_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleConfirmingContext.title',
          '清除排班',
        ],
        showInputs: false,
        description: (context, event) => {
          const { data } = event as unknown as { data: DialogActionEvent };
          const { startAt, endAt } = data.shiftEvent;
          return startAt && endAt
            ? [
                'machines.scheduleDialogMachine.dialogActions.clearScheduleConfirmingContext.description',
                `將清除 {{schedule}} 的排班`,
                { schedule: `${fToDate(startAt)} ~ ${fToDate(endAt)}` },
              ]
            : undefined;
        },
        showCancel: true,
        showClose: true,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleConfirmingContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.clearScheduleConfirmingContext.confirm',
          '確認清除',
        ],
        confirmColor: 'error',
      }),
      [DIALOG_ACTIONS.CONFLICT_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.conflictScheduleContext.title',
          '確認排班',
        ],
        showInputs: false,
        description: (context, event) => {
          const { data } = event as unknown as { data: DialogActionEvent };
          const conflictTimeDescription = data.conflictSchedule.reduce(
            (acc, cur) => {
              const date =
                cur.startAt && cur.endAt
                  ? `${fToDate(cur.startAt)} ${fToTime(cur.startAt)}-${fToTime(
                      cur.endAt,
                    )} \r\n`
                  : '';
              return acc + date;
            },
            '',
          );

          return [
            'machines.scheduleDialogMachine.dialogActions.conflictScheduleContext.description',
            `提醒您，以下請假時段將不會變更狀態：\r\n{{conflictTimeDescription}}`,
            {
              conflictTimeDescription,
            },
          ];
        },
        showCancel: false,
        showClose: false,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.conflictScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.conflictScheduleContext.confirm',
          '確認',
        ],
        confirmColor: 'primary',
      }),
      [DIALOG_ACTIONS.NOT_CHANGE_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.notChangeScheduleContext.title',
          '不可變更的排班',
        ],
        showInputs: false,
        description: [
          'machines.scheduleDialogMachine.dialogActions.notChangeScheduleContext.description',
          '當天（含）以前的時段不可被變更',
        ],
        showCancel: false,
        showClose: false,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.notChangeScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.notChangeScheduleContext.confirm',
          '確認',
        ],
        confirmColor: 'primary',
      }),
      [DIALOG_ACTIONS.NOT_ADDED_SCHEDULE_CONTEXT]: assign<
        DialogContext,
        DialogEvent
      >({
        title: [
          'machines.scheduleDialogMachine.dialogActions.notAddedScheduleContext.title',
          '無法新增排班',
        ],
        showInputs: false,
        description: [
          'machines.scheduleDialogMachine.dialogActions.notAddedScheduleContext.description',
          '當天（含）以前的時段無法新增排班',
        ],
        showCancel: false,
        showClose: false,
        cancelText: [
          'machines.scheduleDialogMachine.dialogActions.notAddedScheduleContext.cancel',
          '取消',
        ],
        confirmText: [
          'machines.scheduleDialogMachine.dialogActions.notAddedScheduleContext.confirm',
          '確認',
        ],
        confirmColor: 'primary',
      }),
    },
    services: {
      [DIALOG_SERVICES.INITIAL_CONTEXT]: async () => {
        assign({
          title: [
            'machines.scheduleDialogMachine.dialogServices.initContext.title',
            '新增排班',
          ],
          showInputs: true,
          description: '',
          showCancel: true,
          showClose: true,
          cancelText: [
            'machines.scheduleDialogMachine.dialogServices.initContext.cancel',
            '取消',
          ],
          confirmText: [
            'machines.scheduleDialogMachine.dialogServices.initContext.confirm',
            '確認',
          ],
        });
      },
    },
  },
);
